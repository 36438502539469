import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/Layout"
import Banner from "../components/Banner"

const getError = graphql`
  query {
    fileName: file(relativePath: { eq: "banner-home.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Error = () => {
  const data = useStaticQuery(getError)

  return (
    <Layout>
      <Banner
        title="Page Not Found"
        ishome={false}
        image={data.fileName.childImageSharp.fluid}
      />
      <main>
        <div className="main-content">
          <div className="c-error">
            <div className="container">
              <div className="row">
                <div class="c-error__section">
                  <p>
                    <strong>
                      Unfortunately the page you tried to access was unavailable
                    </strong>
                  </p>
                  <p>
                    This might be because: <br />
                    You may have typed the web address incorrectly. Please check
                    the address and spelling ensuring that it does not contain
                    capital letters or spaces.
                  </p>
                  <p>
                    It is possible that the page you were looking for may have
                    been moved, updated or deleted.
                  </p>
                  <p>
                    <a href="/">Return to the homepage</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default Error
